import React from 'react'
import Nav from './Nav'

const Contact = () => {
  return (
   <div>
     <Nav />
     <section>
       <h1>Contact</h1>
     </section>
   </div>
  )
}

export default Contact
