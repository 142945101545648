import React from 'react';
import Nav from './Nav';

const About = () => {
  return (
    <div>
      <Nav />
      <section>
        <h1>About</h1>
      </section>
    </div>
  )
}

export default About
